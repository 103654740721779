<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Predefined response</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Predefined
                  responses
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn @click="manageCategory()" x-large text> Categories </v-btn>
                  <v-btn
                    @click="createPredefinedResponse()"
                    class="mt-1 btn btn-primary"
                    style="color: #fff"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp; Add
                    new </v-btn
                  >&nbsp;
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div
              class="search-wrapper border-outline mb-5 px-3 py-5 justify-content-start"
            >
              <div class="row">
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Search by title"
                    v-model="search.title"
                    outlined
                    dense
                    v-on:keyup.enter="getAllPredefinedResponse()"
                    @input="search.title = $event !== null ? $event : ''"
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                    :items="categories"
                    v-model="search.category_id"
                    item-value="id"
                    item-text="title"
                    label="Categories"
                    dense
                    outlined
                  ></v-select>
                </v-col>
                <v-col md="2">
                  <v-select
                    :items="search_statuses"
                    v-model="search.status"
                    label="Status"
                    item-text="name"
                    item-value="value"
                    outlined
                    clearable
                    @input="search.status = $event !== null ? $event : ''"
                    v-on:keyup.enter="getAllPredefinedResponse()"
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="12" md="4" class="text-right">
                  <v-btn
                    @click="getAllPredefinedResponse()"
                    class="btn btn-primary w-35 btn-search"
                    :loading="loading"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="table-responsive">
              <v-skeleton-loader type="table-thead" v-if="loading"> </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>

              <table class="table" v-if="!loading">
                <thead>
                  <tr class="text-left">
                    <th
                      class="px-3"
                      style="max-width: 270px !important; white-space: pre-wrap"
                    >
                      Title
                    </th>
                    <th class="px-3">Category</th>
                    <th class="px-3">Status</th>
                    <th class="pr-3 text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-show="predefined_responses.length"
                    v-for="(item, index) in predefined_responses"
                    :key="index"
                  >
                    <td
                      class="px-3 wrap-column"
                    >
                      <a
                        @click="editCategory(item)"
                        class="text-primary font-weight-bolder text-hover-primary mb-1"
                      >
                        {{ item.title }}
                      </a>
                    </td>
                    <td
                      class="px-3 wrap-column"
                    >
                      <ul v-if="item.category_lists">
                        <li v-for="(category, index) in item.category_lists" :key="index">
                          {{ category.title }}
                        </li>
                      </ul>
                      <ul v-else>
                        NA
                      </ul>
                    </td>
                    <td
                      class="px-3 wrap-column"
                    >
                      <span
                        class="badge badge-success text-lg`"
                        v-bind:class="{
                          'badge-success': item.is_active,
                          'badge-danger': !item.is_active,
                        }"
                        >{{ item.is_active ? "Active" : "Inactive" }}</span
                      >
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                          size="sm"
                          variant="link"
                          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                          no-caret
                          right
                          no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link" @click="editPredefinedResponse(item)">
                                <span class="navi-icon">
                                  <i class="fas fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link" @click.prevent="deleteCategory(item)">
                                <span class="navi-icon">
                                  <i class="fas fa-trash"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-show="predefined_responses.length == 0">
                    <td colspan="4" class="text-center">
                      <strong>No data available.</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
              <b-pagination
                v-if="predefined_responses.length > 0"
                class="pull-right mt-7"
                @input="getAllPredefinedResponse"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
                :disabled="loading"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
      <create-or-update
        ref="create-update"
        @refresh="getAllPredefinedResponse"
      ></create-or-update>
    </div>
  </v-app>
</template>
<script>
import CreateOrUpdate from "./CreateOrUpdate";
import PredefinedResponseService from "@/core/services/predefined-response/PredefinedResponseService";
import PredefinedResponseCategoryService from "@/core/services/predefined-response/PredefinedResponseCategoryService";
const predefinedResponseCategory = new PredefinedResponseCategoryService();
const preDefinedResponse = new PredefinedResponseService();
export default {
  components: {
    CreateOrUpdate,
  },
  data() {
    return {
      loading: false,
      search: {
        title: null,
        status: 1,
      },
      predefined_responses: [],
      search_statuses: [
        { name: "Active", value: 1 },
        { name: "Inactive", value: 0 },
      ],
      categories: [],
      total: null,
      perPage: null,
      page: null,
    };
  },
  methods: {
    getAllPredefinedResponse() {
      preDefinedResponse
        .paginate(this.search, this.page)
        .then((response) => {
          this.predefined_responses = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
        })
        .catch((err) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    createPredefinedResponse() {
      this.$refs["create-update"].createPredefinedResponse();
    },
    editPredefinedResponse(item) {
      this.$refs["create-update"].editPredefinedResponse(item);
    },
    deleteCategory(item) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.loading = true;
            preDefinedResponse
              .delete(item.id)
              .then((response) => {
                this.getAllPredefinedResponse();
                this.$snotify.success("Category deleted");
              })
              .catch((err) => {
                this.loading = false;
                this.$snotify.error("Oops something went wrong");
              })
              .finally(() => {
                this.loading = false;
              });
          }
        },
      });
    },
    manageCategory() {
      this.$router.push({
        name: "predefined-response-category",
      });
    },
    getAllActiveCategories() {
      predefinedResponseCategory
        .getAllActive()
        .then((response) => {
          this.categories = response.data.predefinedResponseCategory;
        })
        .catch((err) => {});
    },
  },
  mounted() {
    this.getAllActiveCategories();
    this.getAllPredefinedResponse();
  },
};
</script>
